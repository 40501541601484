$bs-body-color: #676a79;
$background-color: #EFF2F6;



.App {
  text-align: center;
  background-color: $background-color;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  position: relative;
  overflow-x: hidden;
}

/*.main-container1 {
  //background-color: $background-color;
  display: block;
  padding-top: calc(1.5rem + 56px);
  margin-bottom: 1.5rem;
  height: 100vh;
}*/

.main-container {
  padding-top: 10px;
  min-height: calc(100vh - 252px);
}



.text {
  font-size: 14px;
}

@media (max-width: 480px) {
  .text {
    font-size: 16px;
  }
}
