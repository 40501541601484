.sidebar-main {
    position: absolute;
    width: 200px;
    height: 100%;
    maxHeight: 100%;
   /* border-right: 1px solid lightgrey;*/
}

/*.sidebar-main:before {
    content: "";
    position: absolute;
    //border-right: 1px solid lightgrey;
    right: -1px;
    top: -10px;
    height: 10px;
    width: 50%;

}*/

.nav > li > a {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.nav > li > a:hover {
    background-color: #e4e9f2;
    /*background-color: #e9ecef;*/
}

.nav > li > a > svg {
    font-size: 18px;
    margin-right: 7px;
}
