.col-1-5 {
    width: 12.5% !important;
    flex: 0 0 auto !important;
}

.col-2-5 {
    width: 20.83333% !important;
    flex: 0 0 auto !important;
}

.col-3-5 {
    width: 29.16667% !important;
    flex: 0 0 auto !important;
}

.col-4-5 {
    width: 37.5% !important;
}

.col-5-5 {
    width: 45.83333% !important;
    flex: 0 0 auto !important;
}

.col-7-5 {
    width: 62.5% !important;
    flex: 0 0 auto !important;
}

.col-8-5 {
    width: 70.83333% !important;
    flex: 0 0 auto !important;
}

.col-9-5 {
    width: 79.16667% !important;
    flex: 0 0 auto !important;
}

.col-10-5 {
    width: 87.5% !important;
    flex: 0 0 auto !important;
}

.col-11-5 {
    width: 95.8333% !important;
    flex: 0 0 auto !important;
}


.col-left {
    flex: 0 0 auto !important;
    width: 12.5% !important;
    margin-right: 4px !important;
}

.footer {

    background-color: #F0F2F6 !important;
}

.col-right {
    padding-top: 8px;
    min-height: 100%;
}

.row-middle {
    /*  padding-top: 8px;*/
    min-height: 100%;
}

.col-right {
    width: 200px !important;
    height: 100%;
    min-height: 100%;
}


/*navbar*/


/*.navbar-search:focus {
    background-color: white !important;
}*/

