
input.navbar-search {
  background-color: #212529;
  padding-left: 7px;
  margin-left: 60px;
  flex-flow: column;
}

input.navbar-search:focus {
  background-color: #212529;
  color: aliceblue;
}
